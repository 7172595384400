/* eslint-disable iventis/filenames */
import { createMachine, sendParent, TransitionConfig } from "xstate";
import { choose } from "xstate/lib/actions";
import { EngineInterpreter } from "../bridge/engine-generic";
import { CompositionMapObject, MapCursor } from "../types/internal";
import { deleteCoordinate, isCompositionValid, isFirstNodeInFeature, lineStringCoordinateOnEnd } from "../utilities/geojson-helpers";
import {
    AddCoordinateDeleteEvent,
    ClickMidpointEvent,
    DragMidpointEvent,
    AppendEvent,
    DeleteNodeEvent,
    EndNodeDragEvent,
    EndRotationDragEvent,
    machineEventTypes,
    BeginObjectDragEvent,
    BeginRotatorDragEvent,
    AddMidpointEvent,
    DrawingCallbacks,
    DrawingMachineContext,
    DrawingMachineEvents,
    OptionalDrawingBehaviour,
    DrawingEventNames,
    BeginDragEvent,
    EnterMidpointEvent,
    EndObjectDraggingEvent,
    EnterNodeEvent,
    FinishDrawingEvent,
    NodeClickedEvent,
} from "./map-machines.types";
import { generateCompositionError } from "./mode-machine.helpers";

// eslint-disable-next-line no-undef
type DrawingMachineTypeGen = import("./drawing-machine.typegen").Typegen0;

export const DRAWING_MACHINE_ID = "drawing-machine";

const addMidpoint = <TDragging extends boolean, TEvent extends TDragging extends true ? DragMidpointEvent : ClickMidpointEvent>(dragging: TDragging) =>
    sendParent<DrawingMachineContext, TEvent, AddMidpointEvent>((_, { payload }: TEvent) => ({
        type: DrawingCallbacks.ADD_MIDPOINT,
        payload: { ...payload, dragging },
    }));

export const drawingMachine = (engine: EngineInterpreter) =>
    createMachine(
        {
            id: DRAWING_MACHINE_ID,
            context: { defaultCursor: MapCursor.READ, includeBehaviours: [] },
            schema: {
                context: {} as DrawingMachineContext,
                events: {} as DrawingMachineEvents,
            },
            // eslint-disable-next-line no-undef
            tsTypes: {} as import("./drawing-machine.typegen").Typegen0,
            initial: "default",
            states: {
                default: {
                    entry: [
                        () => engine.enableDragPan(),
                        "removeCoordinateDelete",
                        "removeContinueDrawing",
                        ({ defaultCursor }) => {
                            engine.setCursor(defaultCursor);
                        },
                    ],
                    on: {
                        MOVE_CLOSE_TO_NODE: {
                            target: "closeToNode",
                        },
                        ENTER_ROTATOR: {
                            target: "hoverOverRotator",
                        },
                        ENTER_NODE: {
                            target: `#drawing-machine.closeToNode.hoverOverNode`,
                        },
                        ENTER_MIDPOINT: {
                            target: "hoverOverMidpoint",
                        },
                        NODE_DRAG_START: {
                            target: "nodeDragging",
                        },
                        APPEND: [
                            {
                                cond: (_, event: AppendEvent) => engine.areGeometriesOutsideCadBounds([event.payload.feature.geometry]),
                                actions: "userHasDrawnOutsideCad",
                            },
                            {
                                actions: (_, event: AppendEvent) => {
                                    engine.compositionSetFeature(event.payload);
                                },
                            },
                        ],
                        OVER_SELECTED_OBJECT: [{ cond: "includeBehaviour", actions: () => engine.setCursor(MapCursor.MOVE) }],
                        LEAVE_SELECTED_OBJECT: [{ cond: "includeBehaviour", actions: "resetCursor" }],
                        OBJECT_DRAG_START: [{ cond: "includeBehaviour", target: "objectDragging" }],
                        ROTATOR_DRAG_START: [{ cond: "includeBehaviour", target: "rotating" }],
                    },
                },
                objectDragging: {
                    id: "objectDragging",
                    entry: [
                        (_, event: BeginObjectDragEvent) => {
                            engine.beginDraggingBehaviour(event.payload);
                            engine.setCursor(MapCursor.MOVE);
                        },
                        "tellStoreDraggingHasStarted",
                    ],
                    exit: ["tellStoreDraggingHasEnded"],
                    on: {
                        [machineEventTypes.objectDragEnd as string]: [
                            {
                                cond: (_, { payload }: EndObjectDraggingEvent) => engine.areCompositionObjectsOutsideCadBounds(payload),
                                target: "default",
                                actions: [
                                    "userHasDrawnOutsideCad",
                                    sendParent((_, { payload }: EndObjectDraggingEvent) => ({ type: DrawingCallbacks.OBJECT_DRAG_HAS_ENDED, payload })),
                                ],
                            },
                            {
                                target: "default",
                                actions: sendParent((_, { payload }: EndObjectDraggingEvent) => ({ type: DrawingCallbacks.OBJECT_DRAG_HAS_ENDED, payload })),
                            },
                        ],
                    },
                },
                hoverOverRotator: {
                    entry: [sendParent({ type: DrawingCallbacks.ENTER_HOVER }), () => engine.setCursor(MapCursor.MOVE)],
                    exit: sendParent({ type: DrawingCallbacks.EXIT_HOVER }),
                    on: {
                        ROTATOR_DRAG_START: {
                            target: "rotating",
                        },
                        LEAVE_ROTATOR: {
                            target: "default",
                        },
                    },
                },
                rotating: {
                    entry: [sendParent((_, { payload }: BeginRotatorDragEvent) => ({ type: DrawingCallbacks.ROTATION_HAS_STARTED, payload })), "tellStoreDraggingHasStarted"],
                    exit: choose([
                        {
                            cond: (_, { payload }: EndRotationDragEvent) => engine.areCompositionObjectsOutsideCadBounds(payload),
                            actions: [
                                "userHasDrawnOutsideCad",
                                sendParent((_, { payload }: EndRotationDragEvent) => ({ type: DrawingCallbacks.ROTATION_HAS_ENDED, payload })),
                                "resetCursor",
                                "tellStoreDraggingHasEnded",
                            ],
                        },
                        {
                            actions: [
                                sendParent((_, { payload }: EndRotationDragEvent) => ({ type: DrawingCallbacks.ROTATION_HAS_ENDED, payload })),
                                "resetCursor",
                                "tellStoreDraggingHasEnded",
                            ],
                        },
                    ]),
                    on: {
                        DRAG_END: {
                            target: "default",
                        },
                    },
                },
                hoverOverMidpoint: {
                    entry: [sendParent({ type: DrawingCallbacks.ENTER_HOVER }), (_, event: EnterMidpointEvent) => engine.setCursor(event.payload?.cursor ?? MapCursor.MOVE)],
                    exit: sendParent({ type: DrawingCallbacks.EXIT_HOVER }),
                    on: {
                        CLICK_MIDPOINT: {
                            target: "#drawing-machine.closeToNode.hoverOverNode",
                            actions: [addMidpoint(false), () => engine.setCursor(MapCursor.COMPOSITION)],
                        } as TransitionConfig<DrawingMachineContext, DrawingMachineEvents>,
                        DRAG_MIDPOINT: [
                            {
                                // If we're creating a node at the time of dragging a midpoint, add the node and transition to nodeDragging
                                cond: (_, event: DragMidpointEvent) => event.payload.createNode,
                                target: "nodeDragging",
                                actions: [addMidpoint(true), () => engine.setCursor(MapCursor.COMPOSITION)],
                            },
                            {
                                // Else, transition to midpointDragging
                                target: "midpointDragging",
                            },
                        ] as TransitionConfig<DrawingMachineContext, DrawingMachineEvents>[],
                        LEAVE_MIDPOINT: "default",
                    },
                },
                midpointDragging: {
                    entry: [sendParent({ type: DrawingCallbacks.MIDPOINT_DRAG_HAS_STARTED }), "tellStoreDraggingHasStarted"],
                    exit: [
                        sendParent((_, { payload }: EndNodeDragEvent) => ({ type: DrawingCallbacks.MIDPOINT_DRAG_HAS_ENDED, payload })),
                        "resetCursor",
                        "tellStoreDraggingHasEnded",
                    ],
                    on: {
                        DRAG_END: {
                            target: "hoverOverMidpoint",
                        },
                    },
                },
                nodeDragging: {
                    entry: [
                        sendParent((_, { payload }: BeginDragEvent) => ({ type: DrawingCallbacks.NODE_DRAG_HAS_STARTED, payload })),
                        (_, event: BeginDragEvent) => engine.setCursor(event.payload.cursor ?? MapCursor.MOVE),
                        "tellStoreDraggingHasStarted",
                    ],
                    exit: choose([
                        {
                            cond: (_, { payload }: EndNodeDragEvent) =>
                                engine.areGeometriesOutsideCadBounds(payload?.transformed?.geometry != null ? [payload.transformed.geometry] : []),
                            actions: [
                                "userHasDrawnOutsideCad",
                                sendParent((_, { payload }: EndNodeDragEvent) => ({
                                    type: DrawingCallbacks.NODE_DRAG_HAS_ENDED,
                                    payload: { ...payload, isNodeOutSideCadBounds: true },
                                })),
                                "resetCursor",
                                "tellStoreDraggingHasEnded",
                            ],
                        },
                        {
                            actions: [
                                sendParent((_, { payload }: EndNodeDragEvent) => ({
                                    type: DrawingCallbacks.NODE_DRAG_HAS_ENDED,
                                    payload: { ...payload, isNodeOutSideCadBounds: false },
                                })),
                                "resetCursor",
                                "tellStoreDraggingHasEnded",
                            ],
                        },
                    ]),
                    on: {
                        DRAG_END: {
                            target: "#drawing-machine.closeToNode.hoverOverNode",
                        },
                    },
                },
                closeToNode: {
                    initial: "default",
                    entry: choose([{ cond: "includeBehaviour", actions: ["showCoordinateDelete", "showContinueDrawing"] }, { actions: "showCoordinateDelete" }]),
                    exit: choose([{ cond: "includeBehaviour", actions: ["removeCoordinateDelete", "removeContinueDrawing"] }, { actions: "removeCoordinateDelete" }]),
                    states: {
                        default: {
                            entry: ["resetCursor"],
                            on: {
                                APPEND: [
                                    {
                                        cond: (_, event: AppendEvent) => engine.areGeometriesOutsideCadBounds([event.payload.feature.geometry]),
                                        actions: "userHasDrawnOutsideCad",
                                    },
                                    {
                                        actions: (_, event: AppendEvent) => {
                                            engine.compositionSetFeature(event.payload);
                                        },
                                    },
                                ],
                                OVER_SELECTED_OBJECT: [{ cond: "includeBehaviour", actions: () => engine.setCursor(MapCursor.MOVE) }],
                                LEAVE_SELECTED_OBJECT: { actions: "resetCursor" },
                                OBJECT_DRAG_START: [{ cond: "includeBehaviour", target: "#objectDragging" }],
                                MOVE_CLOSE_TO_NODE: [
                                    {
                                        cond: "includeBehaviour",
                                        actions: ["showCoordinateDelete", "showContinueDrawing"],
                                    },
                                    {
                                        actions: "showCoordinateDelete",
                                    },
                                ],
                                OVER_CONTINUE_DRAWING: { actions: () => engine.setCursor(MapCursor.POINTING) },
                            },
                        },
                        hoverOverNode: {
                            entry: [
                                (_, event: EnterNodeEvent) => {
                                    // Only show pointer on first node in geometry and if its a polygon
                                    if (event.payload.object.geometry.type === "Polygon" && isFirstNodeInFeature(event.payload.object.geometry, event.payload.coordinate)) {
                                        engine.setCursor(event.payload.cursor ?? MapCursor.POINTING);
                                    } else {
                                        engine.setCursor(event.payload.cursor ?? MapCursor.MOVE);
                                    }
                                },
                                "removeCoordinateDelete",
                                "removeContinueDrawing",
                                sendParent({ type: DrawingCallbacks.ENTER_HOVER }),
                            ],
                            exit: ["resetCursor", sendParent({ type: DrawingCallbacks.EXIT_HOVER })],
                            on: {
                                NODE_DRAG_START: {
                                    target: "#drawing-machine.nodeDragging",
                                },
                                NODE_CLICKED: {
                                    cond: "canFinishDrawingOnNodeClick",
                                    actions: sendParent(() => ({ type: machineEventTypes.finish } as FinishDrawingEvent)),
                                },
                                LEAVE_NODE: {
                                    target: "default",
                                },
                            },
                        },
                        hoverOverDelete: {
                            entry: () => engine.setCursor(MapCursor.POINTING),
                            exit: "resetCursor",
                            on: {
                                CLICK_DELETE: [
                                    {
                                        target: "#drawing-machine.default",
                                        cond: "canEditComposition",
                                        actions: (_, event: DeleteNodeEvent) => {
                                            const newObject: CompositionMapObject = engine.deleteNodeWhileInEdit(event.payload.coordinate);
                                            engine.showRotationHandle([newObject]);
                                        },
                                    },
                                    {
                                        actions: ["generateCompositionError"],
                                    },
                                ],
                                LEAVE_DELETE: {
                                    target: "default",
                                },
                                ENTER_NODE: {
                                    target: "hoverOverNode",
                                },
                            },
                        },
                    },
                    on: {
                        MOVE_AWAY: {
                            target: "default",
                        },
                        ENTER_ROTATOR: "hoverOverRotator",
                        ENTER_MIDPOINT: "hoverOverMidpoint",
                        ENTER_NODE: ".hoverOverNode",
                        ENTER_DELETE: ".hoverOverDelete",
                    },
                },
            },
        },
        {
            actions: {
                resetCursor: (context) => engine.setCursor(context.defaultCursor),
                showCoordinateDelete: (_, event: AddCoordinateDeleteEvent | ClickMidpointEvent) => {
                    if (event.payload.object.properties.fixedShape != null) {
                        return;
                    }
                    // The event can be a midpoint click, which means that the coordinate delete will need to show as a result.
                    // It can also just be a regular move close to node, which should show the coordinate delete

                    const coordinate = (event as AddCoordinateDeleteEvent).payload?.coordinate || (event as ClickMidpointEvent).payload?.point?.geometry?.coordinates;
                    if (coordinate) {
                        engine.showCoordinateDelete(coordinate, event.payload.object);
                    }
                },
                removeCoordinateDelete: () => {
                    engine.removeCoordinateDelete();
                },
                generateCompositionError: () => generateCompositionError(engine),
                showContinueDrawing: (_, event: AddCoordinateDeleteEvent | ClickMidpointEvent) => {
                    const coordinate = (event as AddCoordinateDeleteEvent).payload?.coordinate || (event as ClickMidpointEvent).payload?.point?.geometry?.coordinates;
                    if (event.payload.object.geometry.type === "LineString" && !lineStringCoordinateOnEnd(coordinate, event.payload.object.geometry)) {
                        // Hovering over a linestring, do not show the continue drawing handle if we're not over an end coordinate
                        return;
                    }
                    if (event.payload.object.properties.fixedShape != null) {
                        // Do not allow continue drawing if it is a fixed shape
                        return;
                    }
                    engine.showContinueDrawingHandle(coordinate, event.payload.object);
                },
                removeContinueDrawing: () => {
                    engine.removeContinueDrawingHandle();
                },
                tellStoreDraggingHasStarted: () => {
                    engine.tellStoreDraggingHasStarted();
                },
                tellStoreDraggingHasEnded: () => {
                    engine.tellStoreDraggingHasEnded();
                },
                userHasDrawnOutsideCad: () => {
                    engine.userHasDrawnOutsideCad();
                },
            },
            guards: {
                canEditComposition: (_, event: DeleteNodeEvent) =>
                    isCompositionValid(deleteCoordinate(engine.getCurrentComposition(true)?.geojson.geometry, event.payload.coordinate)),
                /** Checks if the relevant behaviour has been switched on in the context */
                includeBehaviour: (context, event: { type: DrawingMachineTypeGen["eventsCausingGuards"]["includeBehaviour"] }) => {
                    switch (event.type) {
                        case DrawingEventNames.MOVE_CLOSE_TO_NODE:
                        case DrawingEventNames.MOVE_AWAY:
                        case DrawingEventNames.ENTER_NODE:
                            return context.includeBehaviours.includes(OptionalDrawingBehaviour.CONTINUE_DRAW);
                        case DrawingEventNames.OBJECT_DRAG_START:
                        case DrawingEventNames.OVER_SELECTED_OBJECT:
                        case DrawingEventNames.LEAVE_SELECTED_OBJECT:
                            return context.includeBehaviours.includes(OptionalDrawingBehaviour.OBJECT_DRAG);
                        default:
                            return true;
                    }
                },
                // Only support finish drawing on node click on polygon shapes
                canFinishDrawingOnNodeClick: (_, event: NodeClickedEvent) =>
                    event.payload.feature.geometry.type === "Polygon" && isFirstNodeInFeature(event.payload.feature.geometry, event.payload.point),
            },
        }
    );
